import { queryCampaigns } from '@/common/api/queryCampaigns';
import { SPECS } from '@/common/constants/specs';
import { getCampaign } from '@/common/api/getCampaign';
import { RootStore } from '@/components/DonationForm/types';
import { DonationCampaign } from '@wix/ambassador-donations-v1-donation-campaign/types';
import { getIsPremium } from '@/common/api/getIsPremium';

export const getInitialData = async ({
  httpClient,
  experiments,
  $state,
}: RootStore) => {
  let campaignPromise: Promise<DonationCampaign | undefined> | undefined;
  if (experiments.enabled(SPECS.LoadFirstCampaign)) {
    campaignPromise = queryCampaigns(httpClient).then((list) => list[0]);
  } else {
    const { campaignId } = $state;
    if (campaignId !== undefined) {
      campaignPromise = getCampaign(httpClient, campaignId);
    } else {
      console.log('"campaignId" is undefined');
    }
  }

  const [campaign, isPremium] = await Promise.all([
    campaignPromise,
    getIsPremium(httpClient),
  ]);
  return {
    campaign,
    isPremium,
  };
};
