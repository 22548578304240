import { ExperimentsBag } from '@wix/yoshi-flow-editor';

export const SPECS = {
  LoadFirstCampaign: 'specs.donations.LoadFirstCampaign',
} as const;

const openedExperimentsForTests: string[] = [];

const openedExperimentsForSled: string[] = [];

export const getOpenedExperimentsForTests = (): ExperimentsBag => {
  return openedExperimentsForTests.reduce((prv: any, cur: any) => {
    prv[cur] = 'true';
    return prv;
  }, {});
};

export const getOpenedExperimentsForSled = () => {
  return openedExperimentsForSled.reduce((obj, ex) => {
    obj[ex] = true;
    return obj;
  }, {});
};
